import React from "react"
import Footer from "../../components/Footer"
import Subscribe from "../../components/investors/Subscribe"
import Layout from "../../components/layout"
import News from "../../components/News"
import NewsClientNew from "../../components/NewsClientNew"
import PageHeader from "../../components/PageHeader"
import Seo from "../../components/seo"
export default function PressReleasesPage() {
  const pageTitle = "Press Releases"
  return (
    <Layout isInvestor pageTitle={pageTitle}>
      <Seo title={pageTitle} />
      <div className="py-8 md:py-16 px-4">
        <PageHeader text="Press Releases" />
        <NewsClientNew />
        <Subscribe />
      </div>
      <Footer />
    </Layout>
  )
}
